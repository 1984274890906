<template>
  <v-app>
    <NavigationDrawer />
    <v-main id="main-content">
      <v-fade-transition mode="out-in">
        <router-view class="content" />
      </v-fade-transition>
      <Loading />
    </v-main>
  </v-app>
</template>

<script>
import NavigationDrawer from '@/components/NavigationDrawer.vue'
import Loading from '@/components/Loading.vue'

export default {
  name: 'DefaultLayout',
  components: {
    NavigationDrawer,
    Loading
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 15px;
}
</style>
