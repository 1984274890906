import Vue from 'vue'
import Vuex from 'vuex'
import { vuexUser } from '@/plugins/VuexPersist'

import UserModule from './user'
import LoadingModule from './loading'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    User: UserModule,
    Loading: LoadingModule
  },
  plugins: [
    vuexUser.plugin
  ]
})

export default store
