export default {
  path: '/psychosocial-clinic-catagory',
  component: () => import('@/views/psychosocialClinicCatagory/PsychosocialClinicCatagory.vue'),
  children: [
    {
      path: '',
      name: 'PsychosocialClinicCatagoryListPage',
      component: () => import('@/views/psychosocialClinicCatagory/pages/PsychosocialClinicCatagoryList.vue'),
      meta: {
        menu: {
          title: 'จัดการประเภทศาล'
        }
      }
    },
    {
      path: 'create',
      name: 'PsychosocialClinicCatagoryCreatePage',
      component: () => import('@/views/psychosocialClinicCatagory/pages/PsychosocialClinicCatagoryCreate.vue')
    },
    {
      path: 'edit/:id',
      name: 'PsychosocialClinicCatagoryEditPage',
      component: () => import('@/views/psychosocialClinicCatagory/pages/PsychosocialClinicCatagoryEdit.vue')
    }
  ]
}
