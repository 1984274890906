import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#004D40',
        secondary: '#FFC107',
        error: '#EF5350',
        info: '#2196F3',
        success: '#2E7D32',
        warning: '#FDD835'
      }
    }
  }
})
