import Vue from 'vue'

const number = (evt) => {
  const event = (evt) || window.event
  const charCode = (event.which) ? event.which : event.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    event.preventDefault()
    return false
  }
  return true
}

const numberNoDecimal = (evt) => {
  const event = (evt) || window.event
  const charCode = (event.which) ? event.which : event.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) {
    event.preventDefault()
    return false
  }
  return true
}

const telInput = (evt) => {
  const event = (evt) || window.event
  const charCode = (event.which) ? event.which : event.keyCode
  if (((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) && charCode !== 43) {
    event.preventDefault()
    return false
  }
  return true
}

Vue.prototype.$keypress = {
  number,
  numberNoDecimal,
  telInput
}
