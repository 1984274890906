export default {
  path: '/user-agency',
  component: () => import('@/views/userAgency/UserAgency.vue'),
  children: [
    {
      path: '',
      name: 'UserAgencyListPage',
      component: () => import('@/views/userAgency/pages/UserAgencyList.vue'),
      meta: {
        menu: {
          title: 'จัดการตำแหน่งของผู้ใช้งาน'
        }
      }
    },
    {
      path: 'create',
      name: 'UserAgencyCreatePage',
      component: () => import('@/views/userAgency/pages/UserAgencyCreate.vue')
    },
    {
      path: 'edit/:id',
      name: 'UserAgencyEditPage',
      component: () => import('@/views/userAgency/pages/UserAgencyEdit.vue')
    }
  ]
}
