<template>
  <Default
    v-if="layoutName === 'default'"
    class="layout-default" />
  <Blank
    v-else-if="layoutName === 'blank'"
    class="layout-blank" />
</template>

<script>
import Default from '@/layouts/default.vue'
import Blank from '@/layouts/blank.vue'

export default {
  name: 'App',
  components: {
    Default,
    Blank
  },
  computed: {
    layoutName () {
      const layoutName = this.$route?.meta?.layout?.toLowerCase() || 'default'
      return layoutName
    }
  }
}
</script>
