import Vue from 'vue'
import Router from '@/router'

const mapMenus = (items) => {
  let menu = []

  items.forEach((item) => {
    if (item?.children?.length > 0) {
      const childrenMenu = mapMenus(item.children).map((childrenMenuItem) => ({
        ...childrenMenuItem,
        path: childrenMenuItem.path === '' ? `${item.path}` : `${item.path}/${childrenMenuItem.path}`
      }))
      let newChildrenMenu = childrenMenu
      if (childrenMenu.length > 1) {
        newChildrenMenu = [{
          to: { name: childrenMenu[0].name },
          title: childrenMenu[0].meta.menu.title,
          subMenus: childrenMenu.filter((childrenMenuItem, index) => index !== 0)
        }]
      }
      menu = [
        ...menu,
        ...newChildrenMenu
      ]
    } else if (item?.meta?.menu?.title) {
      menu.push({
        path: item.path,
        to: { name: item.name },
        title: item.meta.menu.title
      })
    }
  })

  return menu
}

Vue.prototype.$menus = mapMenus(Router.options.routes)
