export default {
  path: '/psychologist-position',
  component: () => import('@/views/psychologistPosition/PsychologistPosition.vue'),
  children: [
    {
      path: '',
      name: 'PsychologistPositionListPage',
      component: () => import('@/views/psychologistPosition/pages/PsychologistPositionList.vue'),
      meta: {
        menu: {
          title: 'จัดการตำแหน่งเครือข่ายนักจิตวิทยา'
        }
      }
    },
    {
      path: 'create',
      name: 'PsychologistPositionCreatePage',
      component: () => import('@/views/psychologistPosition/pages/PsychologistPositionCreate.vue')
    },
    {
      path: 'edit/:id',
      name: 'PsychologistPositionEditPage',
      component: () => import('@/views/psychologistPosition/pages/PsychologistPositionEdit.vue')
    }
  ]
}
