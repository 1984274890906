<template>
  <v-navigation-drawer
    permanent
    app>
    <v-list-item class="py-2">
      <div class="d-flex justify-space-between flex-column align-center gap-2 mx-auto">
        <img
          src="/images/court-branding-2.png"
          width="200"
          height="40"
          alt="court-branding" />
        <h1 class="text-subtitle-2">
          ระบบจัดการฐานข้อมูล
        </h1>
      </div>
    </v-list-item>

    <v-divider></v-divider>

    <v-list
      dense
      nav>
      <v-list-item
        v-for="(item, index) in $menus"
        :key="`menu-${index}`"
        :to="item.to"
        link>
        <v-list-item-content>
          <v-list-item-title class="menu-text">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template #append>
      <div class="pa-2">
        <v-btn
          :to="{ name: 'LogoutPage' }"
          color="error"
          block>
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>
