export default {
  path: '/civil-court-type',
  component: () => import('@/views/civilCourtType/CivilCourtType.vue'),
  children: [
    {
      path: '',
      name: 'CivilCourtTypeListPage',
      component: () => import('@/views/civilCourtType/pages/CivilCourtTypeList.vue'),
      meta: {
        menu: {
          title: 'จัดการประเภทศาลชั้นต้นในเขต กทม.'
        }
      }
    },
    {
      path: 'create',
      name: 'CivilCourtTypeCreatePage',
      component: () => import('@/views/civilCourtType/pages/CivilCourtTypeCreate.vue')
    },
    {
      path: 'edit/:id',
      name: 'CivilCourtTypeEditPage',
      component: () => import('@/views/civilCourtType/pages/CivilCourtTypeEdit.vue')
    }
  ]
}
