export default {
  path: '/psychologist',
  component: () => import('@/views/psychologist/Psychologist.vue'),
  children: [
    {
      path: '',
      name: 'PsychologistListPage',
      component: () => import('@/views/psychologist/pages/PsychologistList.vue'),
      meta: {
        menu: {
          title: 'จัดการนักจิตวิทยา'
        }
      }
    },
    {
      path: 'create',
      name: 'PsychologistCreatePage',
      component: () => import('@/views/psychologist/pages/PsychologistCreate.vue')
    },
    {
      path: 'edit/:id',
      name: 'PsychologistEditPage',
      component: () => import('@/views/psychologist/pages/PsychologistEdit.vue')
    }
  ]
}
