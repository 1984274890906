import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAccessToken } from '@/assets/js/auth'

import Auth from '@/router/modules/Auth'
import User from '@/router/modules/User'
import UserAgency from '@/router/modules/UserAgency'
import SpecialCourt from '@/router/modules/SpecialCourt'
import PsychosocialClinicCatagory from '@/router/modules/PsychosocialClinicCatagory'
import PsychologistPosition from '@/router/modules/PsychologistPosition'
import OfficeOfTheChiefJudge from '@/router/modules/OfficeOfTheChiefJudge'
import CivilCourtType from '@/router/modules/CivilCourtType'
import Psychologist from '@/router/modules/Psychologist'
import LecturerNetwork from '@/router/modules/LecturerNetwork'
import PsychoSocialClinic from '@/router/modules/PsychoSocialClinic'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomePage.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/media-library',
    name: 'MediaLibraryPage',
    component: () => import('@/views/mediaLibrary/MediaLibrary.vue'),
    meta: {
      auth: true,
      menu: {
        title: 'คลังสื่อ'
      }
    }
  },
  Auth,
  User,
  UserAgency,
  SpecialCourt,
  PsychosocialClinicCatagory,
  PsychologistPosition,
  Psychologist,
  OfficeOfTheChiefJudge,
  CivilCourtType,
  LecturerNetwork,
  PsychoSocialClinic,
  {
    path: '/banner-image',
    name: 'BannerImagePage',
    component: () => import('@/views/bannerImage/BannerImage.vue'),
    meta: {
      auth: true,
      menu: {
        title: 'หน้าจัดการ Banner หน้าหลัก'
      }
    }
  },
  {
    path: '/knowledge',
    name: 'KnowledgePage',
    component: () => import('@/views/knowledge/Knowledge.vue'),
    meta: {
      auth: true,
      menu: {
        title: 'หน้าจัดการสื่อการเรียนรู้'
      }
    }
  },
  {
    path: '/*',
    name: 'PageNotFound',
    component: () => import('@/views/errorPage/404.vue'),
    meta: {
      layout: 'Blank'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta?.auth) {
    const token = getAccessToken()
    if (!token) {
      return router.replace({ name: 'LogoutPage' })
    }
  }
  return next()
})

export default router
