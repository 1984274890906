import VuexPersistence from 'vuex-persist'
import Cookies from 'js-cookie'
import Base64 from './Base64'

const ECODE_KEY = process.env.VUE_APP_ENCODE_KEY || ''

export const vuexUser = new VuexPersistence({
  key: '_court_user',
  restoreState: (key) => {
    let cookieData = Cookies.get(key) || ''
    if (cookieData && typeof cookieData === 'string') {
      cookieData = JSON.parse(Base64.decodeByKey(cookieData, ECODE_KEY))
    }
    return {
      User: {
        ...cookieData
      }
    }
  },
  saveState: (key, state) => {
    const stateUser = state?.User || {}
    const cookieData = {
      accessToken: stateUser?.accessToken || null,
      username: stateUser?.username || null,
      role: stateUser?.role || null,
      tokenExpire: stateUser?.tokenExpire || null,
      user: {
        id: stateUser?.user?.id || null,
        firstname: stateUser?.user?.firstname || null,
        lastname: stateUser?.user?.lastname || null,
        email: stateUser?.user?.email || null,
        agency: stateUser?.user?.agency || null
      }
    }
    const expires = cookieData.tokenExpire ? new Date(cookieData.tokenExpire) : 6
    Cookies.set(key, Base64.encodeByKey(JSON.stringify(cookieData), ECODE_KEY), {
      expires
    })
  },
  modules: ['User']
})

export default {
  vuexUser
}
