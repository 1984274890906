export default {
  path: '/auth',
  component: () => import('@/views/auth/Auth.vue'),
  children: [
    {
      path: '',
      name: 'AuthPage',
      redirect: { name: 'LoginPage' }
    },
    {
      path: 'login',
      name: 'LoginPage',
      component: () => import('@/views/auth/pages/Login.vue'),
      meta: {
        layout: 'blank'
      }
    },
    {
      path: 'logout',
      name: 'LogoutPage',
      component: () => import('@/views/auth/pages/Logout.vue'),
      meta: {
        layout: 'blank'
      }
    },
    {
      path: '*',
      name: 'Auth404Page',
      redirect: { name: 'LoginPage' }
    }
  ]
}
