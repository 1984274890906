import Vue from 'vue'
import App from '@/App.vue'
import Router from '@/router'
import Store from '@/store'

import Vuetify from '@/plugins/Vuetify'
import Notification from '@/services/notification'

import '@/assets/scss/style.scss'

import '@/plugins/Dayjs'
import '@/plugins/Keypress'
import '@/plugins/Validation'
import '@/plugins/MapMenu'

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.prototype.$notify = new Notification()

new Vue({
  router: Router,
  store: Store,
  vuetify: Vuetify,
  render: (h) => h(App)
}).$mount('#app')
