export default {
  path: '/special-court',
  component: () => import('@/views/specialCourt/SpecialCourt.vue'),
  children: [
    {
      path: '',
      name: 'SpecialCourtListPage',
      component: () => import('@/views/specialCourt/pages/SpecialCourtList.vue'),
      meta: {
        menu: {
          title: 'จัดการศาลพิเศษ'
        }
      }
    },
    {
      path: 'create',
      name: 'SpecialCourtCreatePage',
      component: () => import('@/views/specialCourt/pages/SpecialCourtCreate.vue')
    },
    {
      path: 'edit/:id',
      name: 'SpecialCourtEditPage',
      component: () => import('@/views/specialCourt/pages/SpecialCourtEdit.vue')
    }
  ]
}
