export default {
  path: '/user',
  component: () => import('@/views/user/User.vue'),
  children: [
    {
      path: '',
      name: 'UserListPage',
      component: () => import('@/views/user/pages/UserList.vue'),
      meta: {
        menu: {
          title: 'จัดการผู้ใช้งาน'
        }
      }
    },
    {
      path: 'create',
      name: 'UserCreatePage',
      component: () => import('@/views/user/pages/UserCreate.vue')
    },
    {
      path: 'edit/:id',
      name: 'UserEditPage',
      component: () => import('@/views/user/pages/UserEdit.vue')
    }
  ]
}
