export default {
  path: '/psycho-social-clinic',
  component: () => import('@/views/psychoSocialClinic/PsychoSocialClinic.vue'),
  children: [
    {
      path: '',
      name: 'PsychoSocialClinicListPage',
      component: () => import('@/views/psychoSocialClinic/pages/PsychoSocialClinicList.vue'),
      meta: {
        menu: {
          title: 'จัดการเครือข่ายคลินิคจิตสังคม'
        }
      }
    },
    {
      path: 'create',
      name: 'PsychoSocialClinicCreatePage',
      component: () => import('@/views/psychoSocialClinic/pages/PsychoSocialClinicCreate.vue')
    },
    {
      path: 'edit/:id',
      name: 'PsychoSocialClinicEditPage',
      component: () => import('@/views/psychoSocialClinic/pages/PsychoSocialClinicEdit.vue')
    }
  ]
}
