import Vue from 'vue'

const betweenValidation = (val, min, max) => (val && val.length > (min - 1) && val.length < (max + 1))

const validation = {
  required: (val) => {
    if (Array.isArray(val)) {
      return val.length > 0 || 'จำเป็นต้องกรอก'
    }
    return !!val || 'จำเป็นต้องกรอก'
  },
  email: (val) => !val || /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,4}$/.test(val) || 'ต้องเป็นรูปแบบ Email',
  min: (min) => (val) => (val && val.length > (min - 1)) || `ต้องมีตัวอักษรอย่างน้อย ${min} ตัว`,
  max: (max) => (val) => (val && val.length < (max + 1)) || `ต้องมีตัวอักษรอย่างน้อย ${max} ตัว`,
  between: (min, max) => (val) => betweenValidation(val, min, max) || `ต้องมีตัวอักษรอย่างน้อย ${min} ตัว แต่ไม่เกิน ${max} ตัว`,
  username: (val) => !val || /^(?=[a-zA-Z0-9._]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/.test(val) || 'ต้องประกอบด้วยตัวหนังสือภาษาอังกฤษ, ตัวเลข และตัวอักษรพิเศษ [a-z 0-9 _ - ]',
  password: (val) => !val || /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&_-]{8,}$/.test(val) || 'ต้องประกอบด้วยตัวหนังสือภาษาอังกฤษ, ตัวเลข และตัวอักษรพิเศษ [A-Z a-z 0-9 Special character]'
}

Vue.prototype.$validation = validation

export default validation
