export default {
  path: '/office-of-the-chief-judge',
  component: () => import('@/views/officeOfTheChiefJudge/OfficeOfTheChiefJudge.vue'),
  children: [
    {
      path: '',
      name: 'OfficeOfTheChiefJudgeListPage',
      component: () => import('@/views/officeOfTheChiefJudge/pages/OfficeOfTheChiefJudgeList.vue'),
      meta: {
        menu: {
          title: 'จัดการสำนักงานศาลยุติธรรมประจำภาค'
        }
      }
    },
    {
      path: 'create',
      name: 'OfficeOfTheChiefJudgeCreatePage',
      component: () => import('@/views/officeOfTheChiefJudge/pages/OfficeOfTheChiefJudgeCreate.vue')
    },
    {
      path: 'edit/:id',
      name: 'OfficeOfTheChiefJudgeEditPage',
      component: () => import('@/views/officeOfTheChiefJudge/pages/OfficeOfTheChiefJudgeEdit.vue')
    }
  ]
}
