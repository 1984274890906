const state = {
  loadingCount: 0
}

const actions = {
  addLoading ({ commit, getters }) {
    commit('SET_LOADING_COUNT', getters.loadingCount + 1)
  },
  removeLoading ({ commit, getters }) {
    let count = getters.loadingCount - 1
    if (count < 0) {
      count = 0
    }
    commit('SET_LOADING_COUNT', count)
  },
  clearLoading ({ commit }) {
    commit('SET_LOADING_COUNT', 0)
  }
}

const mutations = {
  SET_LOADING_COUNT (state, payload) {
    state.loadingCount = payload
  }
}

const getters = {
  isLoading: (state) => state.loadingCount > 0,
  loadingCount: (state) => state.loadingCount
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
