import HttpRequest from './HttpRequest'

class AuthProvider extends HttpRequest {
  login (payload) {
    return this.post('/auth/local?populate=*', payload)
  }

  getUser (accessToken) {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${accessToken}`
    })
    return this.get('/users/me', {
      populate: '*'
    })
  }
}

export default AuthProvider
