import Vue from 'vue'
import Vuetify from '@/plugins/Vuetify'
import Alert from './components/Alert.vue'
import Snackbar from './components/Snackbar.vue'

export default class Notification {
  constructor () {
    this.vue = Vue
    this.colorType = {
      success: Vuetify.preset.theme.themes.light.success,
      error: Vuetify.preset.theme.themes.light.error,
      warning: Vuetify.preset.theme.themes.light.warning,
      info: Vuetify.preset.theme.themes.light.primary
    }
  }

  color (propData) {
    let color = propData?.color
    if (!color) {
      switch (propData.type) {
        case 'success':
          color = this.colorType.success
          break
        case 'error':
          color = this.colorType.error
          break
        case 'warning':
          color = this.colorType.warning
          break
        case 'info':
          color = this.colorType.info
          break
        default:
          color = propData?.color
          break
      }
    }
    return color
  }

  open (propsData) {
    const color = this.color(propsData)
    if (propsData.mode === 'alert') {
      return new (this.vue.extend(Alert))({
        el: document.createElement('div'),
        vuetify: Vuetify,
        propsData: {
          ...propsData,
          color
        }
      })
    }
    return new (this.vue.extend(Snackbar))({
      el: document.createElement('div'),
      vuetify: Vuetify,
      propsData: {
        ...propsData,
        color
      }
    })
  }

  success (message, options = {}) {
    return this.open({
      mode: 'snackbar',
      type: 'success',
      icon: 'mdi-check-circle-outline',
      color: this.colorType.success,
      message,
      ...options
    })
  }

  error (message, options = {}) {
    return this.open({
      mode: 'snackbar',
      type: 'error',
      icon: 'mdi-close-circle-outline',
      color: this.colorType.error,
      message,
      ...options
    })
  }

  info (message, options = {}) {
    return this.open({
      mode: 'snackbar',
      type: 'info',
      icon: 'mdi-information-outline',
      color: this.colorType.info,
      message,
      ...options
    })
  }

  warning (message, options = {}) {
    return this.open({
      mode: 'snackbar',
      type: 'warning',
      icon: 'mdi-alert-outline',
      color: this.colorType.warning,
      message,
      ...options
    })
  }

  default (message, options = {}) {
    return this.open({
      mode: 'snackbar',
      type: 'default',
      icon: 'mdi-bell',
      message,
      ...options
    })
  }
}
