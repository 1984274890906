<template>
  <v-overlay
    :value="isLoading"
    z-index="9999999">
    <v-progress-circular
      indeterminate
      size="64" />
  </v-overlay>
</template>

<script>
export default {
  name: 'LoadingComponent',
  computed: {
    isLoading () {
      return this.$store.getters['Loading/isLoading']
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
