<template>
  <v-app>
    <router-view />
    <Loading />
  </v-app>
</template>

<script>
import Loading from '@/components/Loading.vue'

export default {
  name: 'BlankLayout',
  components: { Loading }
}
</script>

<style lang="scss" scoped>
</style>
