export default {
  path: '/lecturer-network',
  component: () => import('@/views/lecturerNetwork/LecturerNetwork.vue'),
  children: [
    {
      path: '',
      name: 'LecturerNetworkListPage',
      component: () => import('@/views/lecturerNetwork/pages/LecturerNetworkList.vue'),
      meta: {
        menu: {
          title: 'จัดการเครือข่ายวิทยากร'
        }
      }
    },
    {
      path: 'create',
      name: 'LecturerNetworkCreatePage',
      component: () => import('@/views/lecturerNetwork/pages/LecturerNetworkCreate.vue')
    },
    {
      path: 'edit/:id',
      name: 'LecturerNetworkEditPage',
      component: () => import('@/views/lecturerNetwork/pages/LecturerNetworkEdit.vue')
    }
  ]
}
